import React from 'react';
import styled from 'styled-components';
import StyledHeaderImage from '../components/header-image';
import Layout from '../components/layout';
import ContactSection from '../components/contact-section';

const Container = styled.div`
  width: 80%;
  margin: 4rem auto;
  max-width: 960px;
`;

const QuestionText = styled.h3`
  font-size: 1.5rem;
`;

const AnswerText = styled.h5`
  font-size: 1rem;
  color: #333;
  padding-bottom: 2rem;
`;

const FaqPage = () => {
  return (
    <Layout>
      <StyledHeaderImage page="faq" text="FAQs" />
      <Container>
        <QuestionText>How long do I have to perform an exchange?</QuestionText>
        <AnswerText>
          From the time of sale of the initial property, you have 45 days to
          declare a new investment and 180 days in total to close on the new
          property.
        </AnswerText>
        <QuestionText>
          Can I exchange my one property for multiple new properties?
        </QuestionText>
        <AnswerText>
          Yes, you can! You must either be acquiring 3 or less new properties,
          or the new properties’ total value must be less than double of the
          current property.
        </AnswerText>
        <QuestionText>Is it really that easy?</QuestionText>
        <AnswerText>
          Yes, it is! Give us a call today and be on your way to investing
          smarter!
        </AnswerText>
      </Container>
      <ContactSection
        header="Don't see your question?"
        subheader="Feel free to ask us directly!"
        buttonText="Contact Us"
      />
    </Layout>
  );
};

export default FaqPage;
