import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { colors } from "../styles"

const BlueContainer = styled.div`
  width: 80%;
  margin: auto;
  max-width: 960px;
  text-align: center;
  padding: 4rem 4rem 6rem;

  @media (max-width: 750px) {
    padding: 2rem 2rem 3rem;
  }
`

const BlueBackground = styled.div`
  background-color: ${colors.primary};
  color: white;
`

const ContactButton = styled(Link)`
  background-color: ${colors.actionButton};
  border: none;
  border-radius: 3px;
  color: white;
  min-width: 480px;
  margin-top: 1rem;
  padding: 1rem 4rem;
  font-size: 1.25rem;
  text-decoration: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.actionButton};
      transform: translateY(5%) scale(0.99);
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    }
  }
`

const ContactText = styled.h1`
  padding-bottom: 2rem;
  line-height: 4rem;

  @media (max-width: 750px) {
    font-size: 1.25rem;
    line-height: 2.5rem;
  }
`

const ContactSection = ({ header, subheader, buttonText }) => (
  <BlueBackground>
    <BlueContainer>
      <ContactText>
        {header}
        <br />
        {subheader}
      </ContactText>
      <ContactButton to="/contact">{buttonText}</ContactButton>
    </BlueContainer>
  </BlueBackground>
)

export default ContactSection
